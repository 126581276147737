.qa_container {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.qacontent {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 800px;
    margin-top: 80px;
    /* display: flex; */
    /* flex-direction: row; */
    align-items: flex-start;
}

.cards_container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.card {
    width: calc(50% - 10px); /* Adjust as needed */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, opacity 0.2s;
}

.card:hover {
    transform: translateY(-5px);
}

.card.hidden {
    display: none;
}

.card_question {
    font-weight: bold;
    margin-bottom: 10px;
}



.submit_button {
    margin-top: 20px;
    text-align: center;
}

.submit_button button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit_button button:hover {
    background-color: #0056b3;
}
.stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .step {
    flex: 1;
    text-align: center;
    font-weight: bold;
    color: #888;
  }
  
  .step.active {
    color: #333;
  }
  
  /* Styling for the form */
  .card_question {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .card_answer label {
    display: block;
    margin-top: 10px;
  }
  
  .card_answer input[type="radio"],
  .card_answer input[type="text"],
  .card_answer select {
    margin-right: 6px;
  }
  
  .card_answer input[type="text"],
  .card_answer select {
    display: block;
    width: 100%;
    height: 36px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 8px;
    margin-top: 10px;
  }
  
  .stepper_buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .stepper_buttons button {
    margin-left: 10px;
  }
  .stepper-container {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .step-content {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 10px;
  }
  .slide-up-enter {
    transform: translateY(100%);
    opacity: 0;
  }
  
  .slide-up-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  }
  
  .slide-up-exit {
    transform: translateY(0);
    opacity: 1;
  }
  
  .slide-up-exit-active {
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  }