body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}
/* .footer {
  /* Styles for the footer */
   /* display:flex;
  justify-content: space-around;
  align-items: center;
  /* position: absolute;
  bottom: 0;
  justify-content: space-between; */
  /* margin-bottom: 18px; */
/* }
.footer-wrapper {
  position: relative;
}
.footimgsize {
  width:48px;
  height:48px;
} */ 
.mobcenter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.table-box {
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
}
.profile-box {
  /* padding:20px; */
  /* padding-left:20px;
  padding-right:20px;
  padding-bottom: 20px; */
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
}
.register-box {
  /* padding:20px; */
  /* padding-left:20px;
  padding-right:20px;
  padding-bottom: 20px; */
  width:50%;
  margin: auto;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
}
.table .thead-light th {
  color: white;
  background-color: #031832;
  border-color: #031832;
}
.th-bg {
  color: white;
  background-color: #031832;
  border-color: #031832;
}
.react-icons .fa-user-cog {
  font-weight: normal;
}
.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  font-size: 13px;
  font-weight: 500;
}
.sharpened-text {
  text-rendering: optimizeLegibility;
}
.sharpened-text {
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: none;
  font-smooth: never;
}
.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.pagination-container button {
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 2px 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-container button:hover {
  background-color: #f5f5f5;
}

.pagination-container button.active {
  background-color: #031832;
  color: #fff;
  border-color: #031832;
}
.custom-select {
  width: 200px; /* Adjust the width as needed */
  height: 200px; /* Adjust the height as needed */
  /* Add any other desired styles */
}
.item {
  margin-top: 20px;
}
.iconsize {
  font-size: 24px;
}
.mal-2 {
  margin-left: 16px;
}
.mal-4 {
  margin-left:24px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* align-items:center */
  /* min-height: 100vh; */
}
.flex {
  display:flex
}
.ml-4 {
  margin-left: 6px;
}
section {
  width: 100%;
  display: inline-block;
  /* background: #ccc; */
  height: 60vh;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  /* text-decoration: underline; */
}
.home section {
  display: list-item;
}
.app_container {
  min-height: 100vh;
  /* margin-top:74px; */
  display: flex;
  flex-direction: column;
}
.footer-distributed {
  background: linear-gradient(to top, #f16000, #f17601,#f38501);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: normal 16px sans-serif;
  padding: 10px;
}
.content {
  flex-grow: 1;
  margin-top: 26px;
  /* margin-bottom: 240px; Adjust this value based on the footer height */
  overflow-y: auto;
}
.footer{
  bottom:0;
  padding: 8px;
  flex-shrink: 0;
  position: fixed;
}
.footer-distributed .footer-left p {
  color: #8f9296;
  font-size: 14px;
  margin: 0;
}
.dropdown-menu {
  position: absolute;
 right:20;
  /* z-index: 999; */
  background-color: white;
  /* border-radius: 5px; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
}

/* .dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

.dropdown-menu li:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dropdown-menu li:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
} */
/* Footer links */
.align-end {
  justify-self: end;
}
input[type=file]::file-selector-button {
  margin-right: 20px;
  width: 50%;
  max-width: 100%;
  border: none;
  background: rgb(79, 187, 222);
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  background-origin: padding-box;
  position: relative;
  /* border: 2px dashed #555; */
  flex-direction: column;
  margin-bottom: 20%;
 
  
}


input[type=file]::file-selector-button:hover {
  background: #0d45a5;

  margin-bottom: 20%;
  
}


.css-1juivf6-MuiTypography-root {
  background-color: white !important;
}
.css-1y942vo-MuiButtonBase-root-MuiButton-root {
  background-color: #0c7def;
}
.navbar-default {
  padding: 10px !important;
  background-color: #fff !important;
  box-shadow:1px 2px 9px #F4AAB9,
  
}
.navbar-header {
  width: 56%;
}
.menulist {
  margin-left: 20px;
}
.navbar-nav {
  flex-direction: row !important;
}
.logdata {
  color: black;
  margin-top: 20px;
  margin-right: 19px;
  
}

.cusbtn {
  height: 34px;
    margin-top: 18px;
    float: right;
    position:relative;

}
.maincont {
  width: 50%;
    margin-left: 16%;
    
}

/* .navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to top, #f16000, #f17601,#f38501);
  padding: 10px;
  color: white;
} */
.navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to top, #f16000, #f17601,#f38501);
  padding: 10px;
  color: white;
  position: fixed;
}
.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to top, #f16000, #f17601,#f38501);
  padding: 10px;
  color: white;
}
.btn-primary {
  background: linear-gradient(to top, #f16000, #f17601,#f38501) !important;
  border: none !important;
}
.btn-danger {
  background: linear-gradient(to top, #f16000, #f17601,#f38501) !important;
}
.modal_close {
  background-color: white;
  width: 30px;
  height: 30px;
  padding:5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.logo {
  font-size: 24px;
  font-weight: bold;
}

.nav-links {
  list-style-type: none;
  display: flex;
  margin-top: 10px;
}

.nav-links li {
  margin-left: 40px;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-weight: 700;
  font-size: 20px;
}

.nav-links li a:hover {
  text-decoration: underline;
}
.logo_img {
  width:8%;
background-color: white;
padding:6px;
border-radius: 100px;
}
.nglvheader .logo_img {
  background-color: transparent !important;
  width:54%;
  /* height:100px */

}
.btn-white {
  margin-left: 40px !important;
  background-color: white;
  padding:4px;
font-weight: 700;
padding-left: 10px !important;
padding-right:10px
}
.themebtnbg {
  background: linear-gradient(to top, #f16000, #f17601,#f38501) !important;
  color:white
}
.sidebar {
  position: fixed;
  /* top:84px; */
  height: 100vh;
  margin-top: 72px;
  width: 140px;
  /* background: linear-gradient(to top, #f16000, #f17601,#f38501) !important; */
  background-color: white;
  transition: all 0.3s;
  border: 0.5px solid gray;
  padding-right: 14px;
  /* z-index: -1; */
  /* overflow-y: auto; */
}

.sidebar.open {
  width: 60px;
}

.toggle-button {
  position: absolute;
  top: 10px;
  right: -30px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.menu li {
  padding: 10px;
  cursor: pointer;
}
.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 28px;
  width: 100%;
}

.background-image {
  background-image: url("./assets/fileupload.png");
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
.chatbox {
  /* width: 300px;
  height: 400px; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  overflow: hidden;
}

.chatbox-messages {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 320px;
  overflow-y: scroll;
}

.chatbox-message {
  width:200px;
  background-color: #f2f2f2;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
}

.chatbox-input {
  display: flex;
  align-items: center;
  padding: 10px;
}

.chatbox-input input {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chatbox-input button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.sender-you {
  background-color: #e3fbe4;
  color: black;
  align-self: flex-end;
}

.sender-other {
  background-color: #f2f2f2;
  
  
  align-self: flex-start;
}
.text-csm {
  font-size: 14px !important;
  margin-bottom: 10px;
}
header {
  background-color: #e3eeda;
  color: black;
  text-align: center;
  /* padding: 8px 0; */
}
.nglvheader {
  /* background-color: #e3eeda;
  color: black;
  text-align: center;
  padding: 8px 0; */
}
.nglvlogo {
  width: 120px;
  margin: -28px auto;
  position: absolute;
}
.nglvheader img {
  /* margin: 0px auto !important; */
}
.nglvnavbar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e3eeda;
  /* padding: 10px; */
  padding-right: 40px;
  color: white;
  position: fixed;
  z-index: 9998;
}
.nglvheader .nav-links li a {
  color:black !important;
  text-decoration: none;
    font-weight: 500;
    font-size: 18px;
}
.content-section {
  padding: 34px 0px;
  /* background-color: #f5f5f5; */
}

.content-container {
  /* max-width: 800px; */
  margin: 0 auto;
}

.content-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.content-section p {
  font-size: 18px;
  line-height: 1;
  color: #555;
  margin-bottom: 20px;
}

/* Buttons */
button {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 10px;
}
button a {
    color:white !important;
}

/* Images */
img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px auto;
}

/* Universal footer */
.universal-footer {
  background-color: #fff3cc;
  padding: 0px;
  text-align: center;
}

.footer-buttons button {
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 10px;
}
.footer-buttons button img {
 width:60%
}
.testimonials-section {
  text-align: center;
  padding: 20px;
}

.testimonial-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.testimonial {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.quote {
  font-size: 18px;
  font-weight: bold;
}

.reference {
  font-size: 14px;
  color: #888;
}
.dynform-container {
  display: flex;
  justify-content: center;
}

/* Reduce the form size */
.dynform {
  width: 100%; /* Adjust the width as needed */
  max-width: 400px; /* Set a maximum width to control the form's size */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
}

/* Make the input fields take full width */
.dynform input[type="text"],
.dynform select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.learnjesus input[type="text"],
.learnjesus select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
/* Style the submit button */
.dynform input[type="submit"] {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}
.survey input[type="submit"] {
  width: 40%;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}
/* Add spacing between form fields */
.dynform label {
  margin-bottom: 6px;
}
.section-to-scroll {
  /* margin-top: 80px; Adjust this value as needed */
}
.nglvfooter{
  /* background-color: #333; Set your desired background color */
  color: white;
  /* padding: 20px; */
  position: absolute;
  bottom: 0;
  width: 100%;
}
.fsize {
  font-size: 14px !important;
}
.ml-6 {
  margin-left: 10px;
}

.mobile-menu-toggle {
  display: none;
}
/* Style when mobile menu is open */

/* Mobile menu toggle button */

@media (max-width: 768px) {
  .sidebar.open {
    width: 100%;
  }

  .toggle-button {
    right: 10px;
  }
 
  .nav-links {
    display: none;
  }
  .mobile-menu-toggle {
    display: block;
  }
  .nglvnavbar-container{
    height:10vh
  }
}
/* Media Queries */
@media only screen and (max-width: 820px) {
 .logo_img {
    width:100% !important
 }
}
@media (max-hight: 600px) {
  .footer-distributed .footer-left, .footer-distributed .footer-right {
    text-align: center;
    min-height: 106px
  }
  .footer-distributed .footer-right {
    float: none;
    margin: 0 auto 20px;
  }
  .footer-distributed .footer-left p.footer-links {
    line-height: 1.8;
  }
}

/* .btn{
position: absolute;
left: 90px;
bottom: 10px;
} */



